import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SuiAccordionModule } from 'ng2-semantic-ui';
import { GraphQLClientModule } from '../shared/graphql/graphql-client.module';
import { SharedModule } from '../shared/shared.module';

import { QuestionnaireComponent } from './questionnaire.component';
import { QuestionAndAnswerComponent } from './question-and-answer/question-and-answer.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { UserCommentsComponent } from './user-comments/user-comments.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    GraphQLClientModule,
    SuiAccordionModule,
    SharedModule
  ],
  declarations: [QuestionnaireComponent, QuestionAndAnswerComponent, ProgressBarComponent, UserCommentsComponent],
  exports: [QuestionnaireComponent]
})
export class QuestionnaireModule { }
