import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { throwError } from 'rxjs';

import * as uuidv4 from 'uuid/v4';
import * as uuidv5 from 'uuid/v5';

import { LocalStorageService } from './local-storage.service';
import { LoggingService } from './logging.service';
import { GetSurveyGQL, Survey, SurveyStatus } from './graphql/graphql-client.service';
import { StartRoutePath, SurveyIdParam } from '../../const.exports';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public userSessionData: {
    survey: Survey;
    userToken: string;
  } = {
    survey: null,
    userToken: ''
    // surveyId: 'TestUmfrageSE',
    // userToken: '7ca95e3b-6ce6-5cec-a5d4-5ffca5a3b87f',
  };

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private log: LoggingService,
    private getSurveyGQL: GetSurveyGQL
  ) { }

  private removeTypenames(obj) {
    for (const prop in obj) {
      if (prop === '__typename') {
        delete obj[prop];
      } else if (typeof obj[prop] === 'object') {
        this.removeTypenames(obj[prop]);
      }
    }
  }

  public setUserSessionData(route: ActivatedRoute, cb: (mode?: string) => void) {
    if (this.userSessionData.survey && this.userSessionData.survey.status === SurveyStatus.Inactive) {
      this.router.navigate(['/notPublished']);
    }

    if (!this.userSessionData.survey) {
      const surveyId = route.snapshot.queryParamMap.get(SurveyIdParam);
      if (surveyId) {
        this.getSurveyGQL.fetch({ id: surveyId }, { fetchPolicy: 'no-cache' }).subscribe(fetchResult => {
          this.userSessionData.survey = fetchResult.data.getSurvey as Survey;
          this.removeTypenames(this.userSessionData.survey);
          if (this.userSessionData.survey.status === SurveyStatus.Active) {
            cb();
          } else {
            this.router.navigate(['/notPublished']);
          }
        });
      } else {
        cb();
      }
    } else {
      cb();
    }
  }

  public getTimestamp(date?: Date) {
    return Math.floor((date ? date.getTime() : Date.now()) / 1000);
  }

  public getNavExtras(surveyId?: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        [SurveyIdParam]: surveyId ? surveyId : this.userSessionData.survey.id,
      }
    };
    return navigationExtras;
  }

  public generateUUID(data?, namespaceUUID?) {
    return (data && namespaceUUID) ? uuidv5(data, namespaceUUID) : uuidv4();
  }

  public formatTS(ts: number): string {
    return new Date(ts * 1000).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  public currentTS(date?: Date) {
    return Math.floor((date ? date.getTime() : Date.now()) / 1000);
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
      this.log.error('An error occurred: ' + error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      this.log.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
