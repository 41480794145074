import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private log: LoggingService) {
    if (!LocalStorageService.storageAvailable()) {
      this.log.error('localStorage is not available!');
    }
  }

  private static storageAvailable(): boolean {
    const storage = window.localStorage;
    const x = '__storage_test__';

    try {
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return e instanceof DOMException && (
          // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
  }

  get(key): string {
    return localStorage.getItem(key);
  }

  getAsObject(key): any {
    const s = localStorage.getItem(key);
    return s ? JSON.parse(s) : null;
  }

  set(key: string, data: string): void {
    localStorage.setItem(key, data);
  }

  setAsObject(key: string, obj: any): void {
    if (obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    }
  }

  remove(key): void {
    localStorage.removeItem(key);
  }
}
