import { Injectable } from '@angular/core';

import { AudioPath } from '../../const.exports';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private audio: HTMLAudioElement;

  init(fileName: string) {
    this.audio = new Audio(AudioPath + fileName);
    this.audio.addEventListener('loadeddata', () => {
      this.audio.play().then((result) => {
      }, (err) => {
        // console.log('Error playing audio:');
        // console.log(err);
      });
    });
  }

  playOrPause(fileName: string, waitForPreviousAudio: boolean = false) {
    if (this.audio) {
      if (waitForPreviousAudio) {
        this.audio.addEventListener('ended', () => {
          this.init(fileName);
        });
      } else {
        if (!this.audio.src.endsWith(AudioPath + fileName)) {
          this.audio.pause();
          this.init(fileName);
        } else if (this.audio.ended) {
          this.init(fileName);
        } else if (this.audio.paused) {
          this.audio.play();
        } else {
          this.audio.pause();
        }
      }
    } else {
      this.init(fileName);
    }
  }

  stop() {
    if (!this.audio.paused) {
      this.audio.pause();
    }
  }
}
