import { Component, Input } from '@angular/core';
import { VideoPath } from '../../../const.exports';
import { AudioService } from '../audio.service';

@Component({
  selector: 'bkp-video',
  templateUrl: './video.component.html',
  styles: []
})
export class VideoComponent {

  videoPath = VideoPath;

  @Input() src: string;

  constructor(private audioService: AudioService) {
  }

  isPlaying() {
    // Stop audio when video starts to play
    this.audioService.stop();
  }
}
