import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID'];
  surveyId: Scalars['ID'];
  questionId: Scalars['ID'];
  userToken: Scalars['ID'];
  answer?: Maybe<AnswerContent>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
};

export type AnswerChoice = {
  __typename?: 'AnswerChoice';
  idx?: Maybe<Scalars['Int']>;
  choice?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type AnswerConnection = {
  __typename?: 'AnswerConnection';
  items?: Maybe<Array<Maybe<Answer>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type AnswerContent = {
  __typename?: 'AnswerContent';
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  text?: Maybe<Scalars['String']>;
  optionsSelected?: Maybe<Array<Maybe<Option>>>;
  count?: Maybe<Scalars['Int']>;
};

export type AnswerContentInput = {
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  text?: Maybe<Scalars['String']>;
  optionsSelected?: Maybe<Array<Maybe<OptionInput>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Contact = {
  __typename?: 'Contact';
  contactPerson?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  webUrls?: Maybe<Array<Scalars['String']>>;
};

export type ContactInput = {
  contactPerson?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  webUrls?: Maybe<Array<Scalars['String']>>;
};

export type Context = {
  __typename?: 'Context';
  type?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  introductoryVideo?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Question>>;
  contexts?: Maybe<Array<Maybe<Context>>>;
};

export type ContextInput = {
  type?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  introductoryVideo?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionInput>>;
  contexts?: Maybe<Array<Maybe<ContextInput>>>;
};

export type CreateAnswerInput = {
  surveyId: Scalars['ID'];
  questionId: Scalars['ID'];
  userToken: Scalars['ID'];
  answer?: Maybe<AnswerContentInput>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
};

export type CreateOrganizationalUnitInput = {
  parentId?: Maybe<Scalars['ID']>;
  type?: Maybe<OuType>;
  name: Scalars['String'];
  contact?: Maybe<ContactInput>;
  address?: Maybe<AddressInput>;
};

export type CreateQuestionnaireInput = {
  contexts?: Maybe<Array<ContextInput>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CreateSurveyInput = {
  id?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  organizationalUnitId: Scalars['ID'];
  type: QuestionnaireType;
  questionnaireId: Scalars['ID'];
  customQuestions?: Maybe<Array<QuestionInput>>;
  interviewees?: Maybe<Array<PersonInput>>;
  questionnairesCompleted?: Maybe<Scalars['Int']>;
  deadline: Scalars['Int'];
  status: SurveyStatus;
  published?: Maybe<Scalars['Int']>;
  finalized?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  id: Scalars['ID'];
  type: UserType;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  ouTreeAccessAllowed?: Maybe<Array<Scalars['ID']>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CreateUserQuestionnaireInput = {
  type: QuestionnaireType;
  surveyId: Scalars['ID'];
  userToken: Scalars['ID'];
  questionnaireId: Scalars['ID'];
  questionnaire?: Maybe<CreateQuestionnaireInput>;
  customQuestions?: Maybe<Array<QuestionInput>>;
  status: UserQuestionnaireStatus;
  published?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type DeleteAnswerInput = {
  id: Scalars['ID'];
};

export type DeleteOrganizationalUnitInput = {
  id: Scalars['ID'];
};

export type DeleteQuestionnaireInput = {
  id: Scalars['ID'];
};

export type DeleteSurveyInput = {
  id: Scalars['ID'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type DeleteUserQuestionnaireInput = {
  id: Scalars['ID'];
};

export type EvaluationAnswer = {
  __typename?: 'EvaluationAnswer';
  ivId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  choices?: Maybe<Array<Maybe<AnswerChoice>>>;
};

export type EvaluationContext = {
  __typename?: 'EvaluationContext';
  header?: Maybe<Scalars['String']>;
  questionAndAnswers?: Maybe<Array<QuestionAndAnswers>>;
  contexts?: Maybe<Array<EvaluationContext>>;
};

export enum EvaluationQuestionType {
  Text = 'Text',
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  SingleCount = 'SingleCount',
  MultipleCount = 'MultipleCount'
}

export type EvaluationResult = {
  __typename?: 'EvaluationResult';
  interviewees?: Maybe<Array<Interviewee>>;
  contexts?: Maybe<Array<EvaluationContext>>;
};

export type Interviewee = {
  __typename?: 'Interviewee';
  id: Scalars['ID'];
  role: PersonRole;
  ageGroup?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  qualification?: Maybe<Scalars['String']>;
  migration?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOrganizationalUnit?: Maybe<OrganizationalUnit>;
  updateOrganizationalUnit?: Maybe<OrganizationalUnit>;
  deleteOrganizationalUnit?: Maybe<OrganizationalUnit>;
  createSurvey?: Maybe<Survey>;
  updateSurvey?: Maybe<Survey>;
  deleteSurvey?: Maybe<Survey>;
  createUserQuestionnaire?: Maybe<UserQuestionnaire>;
  updateUserQuestionnaire?: Maybe<UserQuestionnaire>;
  deleteUserQuestionnaire?: Maybe<UserQuestionnaire>;
  createQuestionnaire?: Maybe<Questionnaire>;
  updateQuestionnaire?: Maybe<Questionnaire>;
  deleteQuestionnaire?: Maybe<Questionnaire>;
  createAnswer?: Maybe<Answer>;
  updateAnswer?: Maybe<Answer>;
  deleteAnswer?: Maybe<Answer>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  deleteUser?: Maybe<User>;
};


export type MutationCreateOrganizationalUnitArgs = {
  input: CreateOrganizationalUnitInput;
};


export type MutationUpdateOrganizationalUnitArgs = {
  input: UpdateOrganizationalUnitInput;
};


export type MutationDeleteOrganizationalUnitArgs = {
  input: DeleteOrganizationalUnitInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationUpdateSurveyArgs = {
  input: UpdateSurveyInput;
};


export type MutationDeleteSurveyArgs = {
  input: DeleteSurveyInput;
};


export type MutationCreateUserQuestionnaireArgs = {
  input: CreateUserQuestionnaireInput;
};


export type MutationUpdateUserQuestionnaireArgs = {
  input: UpdateUserQuestionnaireInput;
};


export type MutationDeleteUserQuestionnaireArgs = {
  input: DeleteUserQuestionnaireInput;
};


export type MutationCreateQuestionnaireArgs = {
  input: CreateQuestionnaireInput;
};


export type MutationUpdateQuestionnaireArgs = {
  input: UpdateQuestionnaireInput;
};


export type MutationDeleteQuestionnaireArgs = {
  input: DeleteQuestionnaireInput;
};


export type MutationCreateAnswerArgs = {
  input: CreateAnswerInput;
};


export type MutationUpdateAnswerArgs = {
  input: UpdateAnswerInput;
};


export type MutationDeleteAnswerArgs = {
  input: DeleteAnswerInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['Int'];
  label: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
};

export type OptionInput = {
  id: Scalars['Int'];
  label: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
};

export type OrganizationalUnit = {
  __typename?: 'OrganizationalUnit';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  type?: Maybe<OuType>;
  name: Scalars['String'];
  contact?: Maybe<Contact>;
  address?: Maybe<Address>;
  organizationalUnits?: Maybe<OrganizationalUnitConnection>;
  surveys?: Maybe<SurveyConnection>;
};


export type OrganizationalUnitOrganizationalUnitsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type OrganizationalUnitSurveysArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type OrganizationalUnitConnection = {
  __typename?: 'OrganizationalUnitConnection';
  items?: Maybe<Array<Maybe<OrganizationalUnit>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum OuType {
  AuL = 'AuL',
  Company = 'Company',
  Department = 'Department'
}

export type Person = {
  __typename?: 'Person';
  role: PersonRole;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  eMail: Scalars['String'];
};

export type PersonInput = {
  role: PersonRole;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  eMail: Scalars['String'];
};

export enum PersonRole {
  Employee = 'Employee',
  ManagerDv = 'ManagerDV',
  ManagerPa = 'ManagerPA',
  ManagerBi = 'ManagerBI',
  Employer = 'Employer'
}

export type Query = {
  __typename?: 'Query';
  getOrganizationalUnit?: Maybe<OrganizationalUnit>;
  listOrganizationalUnits?: Maybe<OrganizationalUnitConnection>;
  queryOrganizationalUnitsByParentId?: Maybe<OrganizationalUnitConnection>;
  queryOrganizationalUnitsByName?: Maybe<OrganizationalUnitConnection>;
  getSurvey?: Maybe<Survey>;
  listSurveys?: Maybe<SurveyConnection>;
  querySurveysByOrganizationalUnitId?: Maybe<SurveyConnection>;
  getUserQuestionnaire?: Maybe<UserQuestionnaire>;
  listUserQuestionnaires?: Maybe<UserQuestionnaireConnection>;
  getQuestionnaire?: Maybe<Questionnaire>;
  listQuestionnaires?: Maybe<QuestionnaireConnection>;
  getAnswer?: Maybe<Answer>;
  listAnswers?: Maybe<AnswerConnection>;
  queryAnswersBySurveyId?: Maybe<AnswerConnection>;
  evaluateSurveysByOUAndDate?: Maybe<EvaluationResult>;
  getUser?: Maybe<User>;
  listUsers?: Maybe<UserConnection>;
};


export type QueryGetOrganizationalUnitArgs = {
  id: Scalars['ID'];
};


export type QueryListOrganizationalUnitsArgs = {
  filter?: Maybe<TableOrganizationalUnitFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryQueryOrganizationalUnitsByParentIdArgs = {
  parentId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryQueryOrganizationalUnitsByNameArgs = {
  name: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGetSurveyArgs = {
  id: Scalars['ID'];
};


export type QueryListSurveysArgs = {
  filter?: Maybe<TableSurveyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryQuerySurveysByOrganizationalUnitIdArgs = {
  organizationalUnitId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGetUserQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryListUserQuestionnairesArgs = {
  filter?: Maybe<TableUserQuestionnaireFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryListQuestionnairesArgs = {
  filter?: Maybe<TableQuestionnaireFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetAnswerArgs = {
  id: Scalars['ID'];
};


export type QueryListAnswersArgs = {
  filter?: Maybe<TableAnswerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryQueryAnswersBySurveyIdArgs = {
  surveyId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryEvaluateSurveysByOuAndDateArgs = {
  ouId: Scalars['ID'];
  date: Scalars['Int'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryListUsersArgs = {
  filter?: Maybe<TableUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  replacesQuestion?: Maybe<Scalars['ID']>;
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  question: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  optionSet?: Maybe<Array<Maybe<Option>>>;
  optionSetId?: Maybe<Scalars['ID']>;
};

export type QuestionAndAnswers = {
  __typename?: 'QuestionAndAnswers';
  id: Scalars['ID'];
  type: EvaluationQuestionType;
  subType?: Maybe<QuestionSubType>;
  question: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  choices?: Maybe<Array<Scalars['String']>>;
  answers?: Maybe<Array<Maybe<EvaluationAnswer>>>;
};

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  items?: Maybe<Array<Maybe<Question>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionInput = {
  id: Scalars['ID'];
  replacesQuestion?: Maybe<Scalars['ID']>;
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  question: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  optionSet?: Maybe<Array<Maybe<OptionInput>>>;
  optionSetId?: Maybe<Scalars['ID']>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id: Scalars['ID'];
  contexts?: Maybe<Array<Context>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type QuestionnaireConnection = {
  __typename?: 'QuestionnaireConnection';
  items?: Maybe<Array<Maybe<Questionnaire>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum QuestionnaireType {
  SelbstCheck = 'SelbstCheck',
  FremdCheckDv = 'FremdCheckDV',
  FremdCheckPa = 'FremdCheckPA',
  FremdCheckBi = 'FremdCheckBI',
  BetriebsCheck = 'BetriebsCheck'
}

export enum QuestionSubType {
  Scale = 'Scale',
  Table = 'Table'
}

export enum QuestionType {
  TextInput = 'textInput',
  SingleCountInput = 'singleCountInput',
  MultipleCountInput = 'multipleCountInput',
  SingleChoice = 'singleChoice',
  MultipleChoice = 'multipleChoice'
}

export type Survey = {
  __typename?: 'Survey';
  id: Scalars['ID'];
  label: Scalars['String'];
  organizationalUnitId: Scalars['ID'];
  type: QuestionnaireType;
  questionnaireId: Scalars['ID'];
  questionnaire?: Maybe<Questionnaire>;
  surveyResult?: Maybe<Questionnaire>;
  customQuestions?: Maybe<Array<Question>>;
  interviewees?: Maybe<Array<Person>>;
  questionnairesCompleted?: Maybe<Scalars['Int']>;
  deadline: Scalars['Int'];
  status: SurveyStatus;
  published?: Maybe<Scalars['Int']>;
  finalized?: Maybe<Scalars['Int']>;
  answers?: Maybe<AnswerConnection>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};


export type SurveyAnswersArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type SurveyConnection = {
  __typename?: 'SurveyConnection';
  items?: Maybe<Array<Maybe<Survey>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum SurveyStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type TableAnswerFilterInput = {
  surveyId?: Maybe<TableIdFilterInput>;
  questionId?: Maybe<TableIdFilterInput>;
  userToken?: Maybe<TableIdFilterInput>;
  answer?: Maybe<AnswerContentInput>;
  created?: Maybe<TableIntFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
};

export type TableBooleanFilterInput = {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
};

export type TableFloatFilterInput = {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  contains?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type TableIdFilterInput = {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  beginsWith?: Maybe<Scalars['ID']>;
};

export type TableIntFilterInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  contains?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TableOrganizationalUnitFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  parentId?: Maybe<TableIdFilterInput>;
  type?: Maybe<TableOuTypeFilterInput>;
  name?: Maybe<TableStringFilterInput>;
};

export type TableOuTypeFilterInput = {
  eq?: Maybe<OuType>;
  ne?: Maybe<OuType>;
};

export type TableQuestionnaireFilterInput = {
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableQuestionnaireTypeFilterInput = {
  eq?: Maybe<QuestionnaireType>;
  ne?: Maybe<QuestionnaireType>;
};

export type TableStringFilterInput = {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
};

export type TableSurveyFilterInput = {
  organizationalUnitId?: Maybe<TableIdFilterInput>;
  type?: Maybe<TableQuestionnaireTypeFilterInput>;
  questionnaireId?: Maybe<TableIdFilterInput>;
  questionnairesCompleted?: Maybe<TableIntFilterInput>;
  deadline?: Maybe<TableIntFilterInput>;
  status?: Maybe<TableSurveyStatusFilterInput>;
  published?: Maybe<TableIntFilterInput>;
  finalized?: Maybe<TableIntFilterInput>;
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableSurveyStatusFilterInput = {
  eq?: Maybe<SurveyStatus>;
  ne?: Maybe<SurveyStatus>;
};

export type TableUserFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  type?: Maybe<UserType>;
  name?: Maybe<TableStringFilterInput>;
  email?: Maybe<TableStringFilterInput>;
  phone?: Maybe<TableStringFilterInput>;
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableUserQuestionnaireFilterInput = {
  type?: Maybe<TableUserQuestionnaireTypeFilterInput>;
  surveyId?: Maybe<TableStringFilterInput>;
  userToken?: Maybe<TableIdFilterInput>;
  questionnaireId?: Maybe<TableIdFilterInput>;
  status?: Maybe<TableUserQuestionnaireStatusFilterInput>;
  published?: Maybe<TableIntFilterInput>;
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableUserQuestionnaireStatusFilterInput = {
  eq?: Maybe<UserQuestionnaireStatus>;
  ne?: Maybe<UserQuestionnaireStatus>;
};

export type TableUserQuestionnaireTypeFilterInput = {
  eq?: Maybe<QuestionnaireType>;
  ne?: Maybe<QuestionnaireType>;
};

export type UpdateAnswerInput = {
  id: Scalars['ID'];
  surveyId?: Maybe<Scalars['ID']>;
  questionId?: Maybe<Scalars['ID']>;
  userToken?: Maybe<Scalars['ID']>;
  answer?: Maybe<AnswerContentInput>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
};

export type UpdateOrganizationalUnitInput = {
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  type?: Maybe<OuType>;
  name?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactInput>;
  address?: Maybe<AddressInput>;
};

export type UpdateQuestionnaireInput = {
  id: Scalars['ID'];
  contexts?: Maybe<Array<ContextInput>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UpdateSurveyInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  organizationalUnitId?: Maybe<Scalars['ID']>;
  type?: Maybe<QuestionnaireType>;
  questionnaireId?: Maybe<Scalars['ID']>;
  customQuestions?: Maybe<Array<QuestionInput>>;
  interviewees?: Maybe<Array<PersonInput>>;
  questionnairesCompleted?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['Int']>;
  status?: Maybe<SurveyStatus>;
  published?: Maybe<Scalars['Int']>;
  finalized?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  type: UserType;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  ouTreeAccessAllowed?: Maybe<Array<Scalars['ID']>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UpdateUserQuestionnaireInput = {
  id: Scalars['ID'];
  type: QuestionnaireType;
  surveyId?: Maybe<Scalars['ID']>;
  userToken?: Maybe<Scalars['ID']>;
  questionnaireId?: Maybe<Scalars['ID']>;
  questionnaire?: Maybe<UpdateQuestionnaireInput>;
  customQuestions?: Maybe<Array<QuestionInput>>;
  status?: Maybe<UserQuestionnaireStatus>;
  published?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<UpdateAnswerInput>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  type: UserType;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  ouTreeAccessAllowed?: Maybe<Array<Scalars['ID']>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserQuestionnaire = {
  __typename?: 'UserQuestionnaire';
  id: Scalars['ID'];
  type: QuestionnaireType;
  surveyId: Scalars['ID'];
  userToken: Scalars['ID'];
  questionnaireId: Scalars['ID'];
  questionnaire?: Maybe<Questionnaire>;
  customQuestions?: Maybe<Array<Question>>;
  status: UserQuestionnaireStatus;
  published?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<Answer>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UserQuestionnaireConnection = {
  __typename?: 'UserQuestionnaireConnection';
  items?: Maybe<Array<Maybe<UserQuestionnaire>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum UserQuestionnaireStatus {
  Published = 'published',
  Private = 'private'
}

export enum UserType {
  Su = 'SU',
  Be = 'BE'
}

export type UpdateSurveyMutationVariables = {
  input: UpdateSurveyInput;
};


export type UpdateSurveyMutation = (
  { __typename?: 'Mutation' }
  & { updateSurvey?: Maybe<(
    { __typename?: 'Survey' }
    & Pick<Survey, 'id'>
  )> }
);

export type GetSurveyQueryVariables = {
  id: Scalars['ID'];
};


export type GetSurveyQuery = (
  { __typename?: 'Query' }
  & { getSurvey?: Maybe<(
    { __typename?: 'Survey' }
    & Pick<Survey, 'id' | 'label' | 'organizationalUnitId' | 'type' | 'questionnaireId' | 'questionnairesCompleted' | 'deadline' | 'status' | 'published' | 'finalized'>
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'created' | 'createdBy'>
      & { contexts?: Maybe<Array<(
        { __typename?: 'Context' }
        & { contexts?: Maybe<Array<Maybe<(
          { __typename?: 'Context' }
          & ContextFieldsFragment
        )>>> }
        & ContextFieldsFragment
      )>> }
    )>, customQuestions?: Maybe<Array<(
      { __typename?: 'Question' }
      & QuestionFieldsFragment
    )>>, interviewees?: Maybe<Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'role' | 'firstName' | 'lastName' | 'eMail'>
    )>>, answers?: Maybe<(
      { __typename?: 'AnswerConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'id' | 'questionId' | 'userToken'>
        & { answer?: Maybe<(
          { __typename?: 'AnswerContent' }
          & Pick<AnswerContent, 'type' | 'text'>
          & { optionsSelected?: Maybe<Array<Maybe<(
            { __typename?: 'Option' }
            & Pick<Option, 'id' | 'label' | 'count'>
          )>>> }
        )> }
      )>>> }
    )> }
  )> }
);

export type ContextFieldsFragment = (
  { __typename?: 'Context' }
  & Pick<Context, 'type' | 'header' | 'section' | 'description' | 'introductoryVideo' | 'info'>
  & { questions?: Maybe<Array<(
    { __typename?: 'Question' }
    & QuestionFieldsFragment
  )>> }
);

export type QuestionFieldsFragment = (
  { __typename?: 'Question' }
  & Pick<Question, 'id' | 'replacesQuestion' | 'type' | 'question' | 'section' | 'label' | 'description' | 'instruction'>
  & { optionSet?: Maybe<Array<Maybe<(
    { __typename?: 'Option' }
    & Pick<Option, 'id' | 'label' | 'count'>
  )>>> }
);

export type GetUserQuestionnaireQueryVariables = {
  id: Scalars['ID'];
};


export type GetUserQuestionnaireQuery = (
  { __typename?: 'Query' }
  & { getUserQuestionnaire?: Maybe<(
    { __typename?: 'UserQuestionnaire' }
    & UserQuestionnaireFieldsFragment
  )> }
);

export type ListUserQuestionnairesQueryVariables = {
  filter?: Maybe<TableUserQuestionnaireFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type ListUserQuestionnairesQuery = (
  { __typename?: 'Query' }
  & { listUserQuestionnaires?: Maybe<(
    { __typename?: 'UserQuestionnaireConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'UserQuestionnaire' }
      & UserQuestionnaireFieldsFragment
    )>>> }
  )> }
);

export type CreateUserQuestionnaireMutationVariables = {
  input: CreateUserQuestionnaireInput;
};


export type CreateUserQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { createUserQuestionnaire?: Maybe<(
    { __typename?: 'UserQuestionnaire' }
    & UserQuestionnaireFieldsFragment
  )> }
);

export type UpdateUserQuestionnaireMutationVariables = {
  input: UpdateUserQuestionnaireInput;
};


export type UpdateUserQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { updateUserQuestionnaire?: Maybe<(
    { __typename?: 'UserQuestionnaire' }
    & UserQuestionnaireFieldsFragment
  )> }
);

export type DeleteUserQuestionnaireMutationVariables = {
  input: DeleteUserQuestionnaireInput;
};


export type DeleteUserQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserQuestionnaire?: Maybe<(
    { __typename?: 'UserQuestionnaire' }
    & Pick<UserQuestionnaire, 'id'>
  )> }
);

export type UserQuestionnaireFieldsFragment = (
  { __typename?: 'UserQuestionnaire' }
  & Pick<UserQuestionnaire, 'id' | 'type' | 'surveyId' | 'userToken' | 'questionnaireId' | 'status' | 'published' | 'created' | 'createdBy' | 'updated' | 'updatedBy'>
  & { questionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'created' | 'createdBy' | 'updated' | 'updatedBy'>
    & { contexts?: Maybe<Array<(
      { __typename?: 'Context' }
      & Pick<Context, 'type' | 'header' | 'section' | 'description' | 'introductoryVideo' | 'info'>
      & { questions?: Maybe<Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'type' | 'question' | 'section' | 'label' | 'description' | 'instruction' | 'optionSetId'>
        & { optionSet?: Maybe<Array<Maybe<(
          { __typename?: 'Option' }
          & Pick<Option, 'id' | 'label' | 'count'>
        )>>> }
      )>>, contexts?: Maybe<Array<Maybe<(
        { __typename?: 'Context' }
        & Pick<Context, 'type' | 'header' | 'section' | 'description' | 'introductoryVideo' | 'info'>
        & { questions?: Maybe<Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'type' | 'question' | 'section' | 'label' | 'description' | 'instruction' | 'optionSetId'>
          & { optionSet?: Maybe<Array<Maybe<(
            { __typename?: 'Option' }
            & Pick<Option, 'id' | 'label' | 'count'>
          )>>> }
        )>>, contexts?: Maybe<Array<Maybe<(
          { __typename?: 'Context' }
          & Pick<Context, 'type' | 'header' | 'section' | 'description' | 'introductoryVideo' | 'info'>
          & { questions?: Maybe<Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'type' | 'question' | 'section' | 'label' | 'description' | 'instruction' | 'optionSetId'>
            & { optionSet?: Maybe<Array<Maybe<(
              { __typename?: 'Option' }
              & Pick<Option, 'id' | 'label' | 'count'>
            )>>> }
          )>> }
        )>>> }
      )>>> }
    )>> }
  )>, customQuestions?: Maybe<Array<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'type' | 'question' | 'section' | 'label' | 'description' | 'instruction' | 'optionSetId'>
    & { optionSet?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'id' | 'label' | 'count'>
    )>>> }
  )>>, answers?: Maybe<Array<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'surveyId' | 'questionId' | 'userToken' | 'created' | 'updated'>
    & { answer?: Maybe<(
      { __typename?: 'AnswerContent' }
      & Pick<AnswerContent, 'type' | 'text' | 'count'>
      & { optionsSelected?: Maybe<Array<Maybe<(
        { __typename?: 'Option' }
        & Pick<Option, 'id' | 'label' | 'count'>
      )>>> }
    )> }
  )>> }
);

export const QuestionFieldsFragmentDoc = gql`
    fragment questionFields on Question {
  id
  replacesQuestion
  type
  question
  section
  label
  description
  instruction
  optionSet {
    id
    label
    count
  }
}
    `;
export const ContextFieldsFragmentDoc = gql`
    fragment contextFields on Context {
  type
  header
  section
  description
  introductoryVideo
  info
  questions {
    ...questionFields
  }
}
    ${QuestionFieldsFragmentDoc}`;
export const UserQuestionnaireFieldsFragmentDoc = gql`
    fragment userQuestionnaireFields on UserQuestionnaire {
  id
  type
  surveyId
  userToken
  questionnaireId
  questionnaire {
    id
    contexts {
      type
      header
      section
      description
      introductoryVideo
      info
      questions {
        id
        type
        question
        section
        label
        description
        instruction
        optionSet {
          id
          label
          count
        }
        optionSetId
      }
      contexts {
        type
        header
        section
        description
        introductoryVideo
        info
        questions {
          id
          type
          question
          section
          label
          description
          instruction
          optionSet {
            id
            label
            count
          }
          optionSetId
        }
        contexts {
          type
          header
          section
          description
          introductoryVideo
          info
          questions {
            id
            type
            question
            section
            label
            description
            instruction
            optionSet {
              id
              label
              count
            }
            optionSetId
          }
        }
      }
    }
    created
    createdBy
    updated
    updatedBy
  }
  customQuestions {
    id
    type
    question
    section
    label
    description
    instruction
    optionSet {
      id
      label
      count
    }
    optionSetId
  }
  status
  published
  created
  createdBy
  updated
  updatedBy
  answers {
    id
    surveyId
    questionId
    userToken
    answer {
      type
      text
      optionsSelected {
        id
        label
        count
      }
      count
    }
    created
    updated
  }
}
    `;
export const UpdateSurveyDocument = gql`
    mutation UpdateSurvey($input: UpdateSurveyInput!) {
  updateSurvey(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSurveyGQL extends Apollo.Mutation<UpdateSurveyMutation, UpdateSurveyMutationVariables> {
    document = UpdateSurveyDocument;
    
  }
export const GetSurveyDocument = gql`
    query GetSurvey($id: ID!) {
  getSurvey(id: $id) {
    id
    label
    organizationalUnitId
    type
    questionnaireId
    questionnaire {
      contexts {
        ...contextFields
        contexts {
          ...contextFields
        }
      }
      created
      createdBy
    }
    customQuestions {
      ...questionFields
    }
    interviewees {
      role
      firstName
      lastName
      eMail
    }
    questionnairesCompleted
    deadline
    status
    published
    finalized
    answers(limit: 2000) {
      items {
        id
        questionId
        userToken
        answer {
          type
          text
          optionsSelected {
            id
            label
            count
          }
        }
      }
    }
  }
}
    ${ContextFieldsFragmentDoc}
${QuestionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSurveyGQL extends Apollo.Query<GetSurveyQuery, GetSurveyQueryVariables> {
    document = GetSurveyDocument;
    
  }
export const GetUserQuestionnaireDocument = gql`
    query GetUserQuestionnaire($id: ID!) {
  getUserQuestionnaire(id: $id) {
    ...userQuestionnaireFields
  }
}
    ${UserQuestionnaireFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserQuestionnaireGQL extends Apollo.Query<GetUserQuestionnaireQuery, GetUserQuestionnaireQueryVariables> {
    document = GetUserQuestionnaireDocument;
    
  }
export const ListUserQuestionnairesDocument = gql`
    query ListUserQuestionnaires($filter: TableUserQuestionnaireFilterInput, $limit: Int, $nextToken: String) {
  listUserQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      ...userQuestionnaireFields
    }
  }
}
    ${UserQuestionnaireFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUserQuestionnairesGQL extends Apollo.Query<ListUserQuestionnairesQuery, ListUserQuestionnairesQueryVariables> {
    document = ListUserQuestionnairesDocument;
    
  }
export const CreateUserQuestionnaireDocument = gql`
    mutation CreateUserQuestionnaire($input: CreateUserQuestionnaireInput!) {
  createUserQuestionnaire(input: $input) {
    ...userQuestionnaireFields
  }
}
    ${UserQuestionnaireFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserQuestionnaireGQL extends Apollo.Mutation<CreateUserQuestionnaireMutation, CreateUserQuestionnaireMutationVariables> {
    document = CreateUserQuestionnaireDocument;
    
  }
export const UpdateUserQuestionnaireDocument = gql`
    mutation UpdateUserQuestionnaire($input: UpdateUserQuestionnaireInput!) {
  updateUserQuestionnaire(input: $input) {
    ...userQuestionnaireFields
  }
}
    ${UserQuestionnaireFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserQuestionnaireGQL extends Apollo.Mutation<UpdateUserQuestionnaireMutation, UpdateUserQuestionnaireMutationVariables> {
    document = UpdateUserQuestionnaireDocument;
    
  }
export const DeleteUserQuestionnaireDocument = gql`
    mutation DeleteUserQuestionnaire($input: DeleteUserQuestionnaireInput!) {
  deleteUserQuestionnaire(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserQuestionnaireGQL extends Apollo.Mutation<DeleteUserQuestionnaireMutation, DeleteUserQuestionnaireMutationVariables> {
    document = DeleteUserQuestionnaireDocument;
    
  }