import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AudioService } from '../shared/audio.service';
import { DataService } from '../shared/data.service';
import { QnnShortType, SurveyIdParam, SurveyRoutePath, UserTokenCacheKey } from '../../const.exports';
import { QuestionnaireType } from '../shared/graphql/graphql-client.service';
import { LocalStorageService } from '../shared/local-storage.service';

@Component({
  selector: 'bkp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  SurveyRoutePath = SurveyRoutePath;
  QuestionnaireType = QuestionnaireType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private audioService: AudioService,
    private dataService: DataService,
  ) {}

  ngOnInit() {
    this.dataService.userSessionData.userToken = this.localStorageService.get(UserTokenCacheKey);
    if (this.dataService.userSessionData.userToken) {
      this.router.navigate(['/' + SurveyRoutePath],
          this.dataService.getNavExtras(this.route.snapshot.queryParamMap.get(SurveyIdParam)));
    } else {
      this.dataService.setUserSessionData(this.route, (mode: string) => {
        // Won't work because the user hasn't interacted with the document first, so automatic playback won't happen.
        this.playDescriptionAudio(true);
      });
    }
  }

  isSE() {
    return this.dataService.userSessionData.survey
      && this.dataService.userSessionData.survey.type === QuestionnaireType.SelbstCheck;
  }

  isFE() {
    return this.dataService.userSessionData.survey
      && (this.dataService.userSessionData.survey.type === QuestionnaireType.FremdCheckDv
      || this.dataService.userSessionData.survey.type === QuestionnaireType.FremdCheckPa
      || this.dataService.userSessionData.survey.type === QuestionnaireType.FremdCheckBi);
  }

  isBC() {
    return this.dataService.userSessionData.survey
      && this.dataService.userSessionData.survey.type === QuestionnaireType.BetriebsCheck;
  }

  start() {
    this.localStorageService.set(UserTokenCacheKey, this.dataService.generateUUID());
    this.router.navigate(['/' + SurveyRoutePath],
        this.dataService.getNavExtras(this.route.snapshot.queryParamMap.get(SurveyIdParam)));
  }

  playDescriptionAudio(waitForPreviousAudio: boolean = false) {
    this.audioService.playOrPause(`${QnnShortType(this.dataService.userSessionData.survey.type)}-0.m4a`);
    this.playInstructionAudio(waitForPreviousAudio);
  }

  playInstructionAudio(waitForPreviousAudio: boolean = false) {
    this.audioService.playOrPause(`${QnnShortType(this.dataService.userSessionData.survey.type)}-0-i.m4a`, waitForPreviousAudio);
  }

}
