import { environment } from './environments/environment';
import { QuestionnaireType } from './app/shared/graphql/graphql-client.service';

export const Title = 'Umfrage';
export const Version = '0.18.1';
export const DeployedOn = '06.10.2020 18:00 Uhr';

export const GraphQLUri = 'https://jtj7wonpijcyhfukdaaremjjy4.appsync-api.eu-central-1.amazonaws.com/graphql';
export const XApiKey = 'da2-ig6ifotemjhgnlld72mngvlhjq';

export const StartRoutePath = 'dashboard';
export const SurveyRoutePath = 'umfrage';
export const SurveyIdParam = 'umfrageId';
export const UserTokenCacheKey = 'BKPSessionToken';
export const UserProgressCacheKey = 'BKPProgressToken_';

export const AlphaDekadeLogoUrl = '/assets/images/logo-alpha-dekade.png';
export const AuLLogoUrl = '/assets/images/logo-aul.png';
export const AuLBBLogoUrl = '/assets/images/logo-aul-bb.png';
export const BKPLogoUrl = '/assets/images/logo-bkp.png';
export const BMBFLogoUrl = '/assets/images/logo-bmbf.svg';
export const KHuFLogoUrl = '/assets/images/logo-khuf.png';

export const AudioPath = '/assets/audios/';
export const VideoPath = '/assets/videos/sd/';

export const QnnShortType = (type: QuestionnaireType) => {
  switch (type) {
    case QuestionnaireType.SelbstCheck: return 'se';
    case QuestionnaireType.FremdCheckDv: return 'fe';
    case QuestionnaireType.FremdCheckPa: return 'fe';
    case QuestionnaireType.FremdCheckBi: return 'fe';
    case QuestionnaireType.BetriebsCheck: return 'bc';
  }
};

export const disableBrowserButtonNav = () => {
  history.pushState(null, null, document.URL);
  window.addEventListener('popstate', () => {
    history.pushState(null, null, document.URL);
  });
};

export const InfoFinish = {
  header: 'Die Umfrage ist beendet.',
  text: 'Vielen Dank für Ihre Teilnahme!'
};

export const InfoNotPublished = {
  header: 'Umfrage noch nicht verfügbar',
  text: 'Die Umfrage kann nicht angezeigt werden, da sie noch nicht gestartet wurde!'
};
