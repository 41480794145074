import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bkp-info',
  templateUrl: './info.component.html',
  styles: []
})
export class InfoComponent implements OnInit {

  infoData: { header: string, text: string };

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { header: string, text: string }) => {
      this.infoData = data;
    });
  }

}
