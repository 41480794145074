import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import {
  Answer,
  AnswerContent,
  Context,
  CreateUserQuestionnaireGQL,
  ListUserQuestionnairesGQL,
  Question,
  QuestionnaireType, UpdateSurveyGQL,
  UpdateUserQuestionnaireGQL,
  UserQuestionnaire,
  UserQuestionnaireStatus
} from '../shared/graphql/graphql-client.service';

import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DataService } from '../shared/data.service';

import objectKeyFilter from 'object-key-filter';
import { StartRoutePath, SurveyRoutePath, UserProgressCacheKey, UserTokenCacheKey } from '../../const.exports';
import { LocalStorageService } from '../shared/local-storage.service';
import { QueryOptionsAlone } from 'apollo-angular/types';

const BigBull = 'C10';
const LongLine = 'L50';
// const SmallBull = 'C6';
// const ShortLine = 'L30';

const progressBarElements = [
  BigBull, LongLine,
  BigBull, LongLine,
  BigBull, LongLine,
  BigBull, LongLine,
  BigBull
];

export interface QuestionContext {
  headerComposite: string;
  headerMain: string;
  descriptionMain: string;
  header: string;
  description: string;
  section: string;
  questionnaireType: QuestionnaireType;
  info: string;
  videoSrc: string;
  question: Question;
  answer: Answer;
  isParent: boolean;
  firstQuestion: boolean;
  lastQuestion: boolean;
}

export interface UserProgress {
  qcCurrentIdx: number;
  qcLastIdx: number;
  currentMainSection: number;
  useElements: Array<any>;
  currentIdx: number;
}

@Component({
  selector: 'bkp-questionnaire',
  templateUrl: './questionnaire.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: []
})
export class QuestionnaireComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  @ViewChild(ProgressBarComponent, {static: false}) public progressBar: ProgressBarComponent;

  // transient
  userQuestionnaire: UserQuestionnaire;
  qcs: Array<QuestionContext> = [];   // QuestionContext's

  // persistent (local storage)
  upgs: UserProgress = {
    qcCurrentIdx: 0,
    qcLastIdx: 0,
    currentMainSection: 1,
    useElements: [],
    currentIdx: 0
  };

  progressBarElements = progressBarElements;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private listUserQuestionnaires: ListUserQuestionnairesGQL,
              private createUserQuestionnaireGQL: CreateUserQuestionnaireGQL,
              private updateUserQuestionnaireGQL: UpdateUserQuestionnaireGQL,
              private updateSurveyGQL: UpdateSurveyGQL,
              private localStorageService: LocalStorageService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.dataService.setUserSessionData(this.route, () => {
      this.loadUserProgress();
      this.dataService.userSessionData.userToken = this.localStorageService.get(UserTokenCacheKey);
      if (this.dataService.userSessionData.userToken) {
        this.listUserQuestionnaires.fetch({
          filter: {
            surveyId: {
              eq: this.dataService.userSessionData.survey.id
            },
            userToken: {
              eq: this.dataService.userSessionData.userToken
            }
          }
        }).subscribe(result => {
          const items = result.data.listUserQuestionnaires.items;
          if (items && items.length > 0) {
            this.userQuestionnaire = items[0];
            this.createQCs(this.userQuestionnaire.questionnaire.contexts, null);
          } else {
            this.createUserQuestionnaire();
          }
        }, error => {
          console.error(error);
        });
      } else {
        this.router.navigate(['/' + StartRoutePath]);
      }
    });
  }

  private createUserQuestionnaire() {
    const survey = this.dataService.userSessionData.survey;
    const createObj = {
      type: survey.type,
      surveyId: survey.id,
      userToken: this.dataService.userSessionData.userToken,
      questionnaireId: survey.questionnaireId,
      questionnaire: survey.questionnaire,
      customQuestions: survey.customQuestions,
      status: UserQuestionnaireStatus.Private,
      created: this.dataService.currentTS(),
      createdBy: this.dataService.userSessionData.userToken
    };
    this.createUserQuestionnaireGQL.mutate({
      input: createObj
    }).subscribe(result => {
      this.userQuestionnaire = result.data.createUserQuestionnaire;
      console.log('UserQuestionnaire ' + this.userQuestionnaire.id + ' created in database.');
      this.createQCs(this.userQuestionnaire.questionnaire.contexts, null);
    }, error => {
      console.error(error);
    });
  }

  private createQCs(contexts: Array<Context>, parentContext: Context) {
    for (let i = 0; i < contexts.length; i++) {
      if (parentContext === null) {
        if (contexts[i].section !== '4' || this.userQuestionnaire.customQuestions.length > 0) {
          const qc: QuestionContext = {} as QuestionContext;

          qc.headerMain = contexts[i].header;
          qc.descriptionMain = contexts[i].description;
          qc.header = '';
          qc.description = '';
          qc.videoSrc = contexts[i].introductoryVideo;
          qc.section = contexts[i].section;
          qc.questionnaireType = this.userQuestionnaire.type;
          qc.isParent = true;

          this.qcs.push(qc);
        }
      }

      if (contexts[i].questions) {
        for (let k = 0; k < contexts[i].questions.length; k++) {
          const qc: QuestionContext = {} as QuestionContext;

          if (parentContext === null) {
            qc.headerComposite = contexts[i].header;
            qc.headerMain = contexts[i].header;
            // qc.descriptionMain = contexts[i].description;
            qc.header = '';
            qc.description = '';
          } else {
            qc.headerComposite = parentContext.header + ' > ' + contexts[i].header;
            qc.headerMain = parentContext.header;
            // qc.descriptionMain = parentContext.description;
            qc.header = contexts[i].header;
            qc.description = contexts[i].description;
          }

          qc.info = contexts[i].info;

          qc.question = contexts[i].questions[k];
          if (this.userQuestionnaire.answers) {
            qc.answer = this.userQuestionnaire.answers.find((a: Answer) => {
              return a.questionId === contexts[i].questions[k].id;
            });
          }
          qc.section = qc.question.section;
          qc.questionnaireType = this.userQuestionnaire.type;

          qc.isParent = false;
          qc.firstQuestion = (k === 0);
          qc.lastQuestion = (k === contexts[i].questions.length - 1);

          this.qcs.push(qc);
        }
      } else if (contexts[i].section === '4') {
        this.insertCustomQuestions();
      }

      if (contexts[i].contexts) {
        this.createQCs(contexts[i].contexts, contexts[i]);
      }
    }
  }

  private insertCustomQuestions() {
    const cqs = this.userQuestionnaire.customQuestions;
    if (!cqs) { return; }

    for (let i = 0; i < cqs.length; i++) {
      const qc: QuestionContext = {} as QuestionContext;

      qc.headerComposite = 'Betriebsspezifische Grundkompetenzen';
      qc.headerMain = '';
      qc.header = '';
      qc.description = '';
      qc.section = '4';
      qc.info = '';

      qc.question = cqs[i];
      if (this.userQuestionnaire.answers) {
        qc.answer = this.userQuestionnaire.answers.find((a: Answer) => {
          return a.questionId === cqs[i].id;
        });
      }
      qc.questionnaireType = this.userQuestionnaire.type;

      qc.isParent = false;
      qc.firstQuestion = (i === 0);
      qc.lastQuestion = (i === cqs.length - 1);

      this.qcs.push(qc);
    }
  }

  private createOrUpdateAnswer(answerContent: AnswerContent) {
    if (answerContent) {
      const qc = this.qcs[this.upgs.qcCurrentIdx];

      if (qc.answer) {
        qc.answer.answer = answerContent;
        qc.answer.updated = this.dataService.currentTS();

        this.userQuestionnaire.answers = this.userQuestionnaire.answers.map((a: Answer) => {
          return (a.questionId === qc.question.id) ? qc.answer : a;
        });
      } else {
        qc.answer = {
          id: this.dataService.generateUUID(),
          surveyId: this.userQuestionnaire.surveyId,
          questionId: qc.question.id,
          userToken: this.userQuestionnaire.userToken,
          answer: answerContent,
          created: this.dataService.currentTS()
        } as Answer;

        this.userQuestionnaire.answers = (this.userQuestionnaire.answers)
          ? [ ...this.userQuestionnaire.answers, qc.answer ]
          : [ qc.answer ];
      }

      let updateObj = {
        ...this.userQuestionnaire,
        updated: this.dataService.currentTS(),
        updatedBy: this.userQuestionnaire.userToken
      };
      updateObj = objectKeyFilter(updateObj, ['__typename'], true);
      delete updateObj.questionnaire;

      this.updateUserQuestionnaireGQL.mutate({
        input: {
          ...updateObj
        }
      }).subscribe();
    }
  }

  loadUserProgress() {
    const obj = this.localStorageService.getAsObject(UserProgressCacheKey + this.dataService.userSessionData.survey.id);
    this.upgs = obj
      ? { ...obj }
      : {
        qcCurrentIdx: 0,
        qcLastIdx: 0,
        currentMainSection: 1,
        useElements: [],
        currentIdx: 0
      };
  }

  saveUserProgress() {
    this.localStorageService.setAsObject(UserProgressCacheKey + this.dataService.userSessionData.survey.id, this.upgs);
  }

  canProgress() {
    return this.qcs[this.upgs.qcCurrentIdx + 1].section.length === 1
        && Number(this.qcs[this.upgs.qcCurrentIdx + 1].section) > this.upgs.currentMainSection;
  }

  next(answerContent: AnswerContent | undefined) {
    this.createOrUpdateAnswer(answerContent);

    if (this.upgs.qcCurrentIdx === this.upgs.qcLastIdx) {
      if (this.upgs.qcLastIdx < this.qcs.length - 1) {
        this.upgs.qcLastIdx++;
      }
      if (this.canProgress()) {
        this.upgs.currentMainSection++;
        this.progressBar.progress();
      }
    } else {
      if (this.canProgress()) {
        this.upgs.currentMainSection++;
        this.progressBar.fwd();
      }
    }
    if (this.upgs.qcCurrentIdx < this.qcs.length - 1) {
      this.upgs.qcCurrentIdx++;
    }

    this.saveUserProgress();
  }

  previous(answerContent: AnswerContent | undefined) {
    this.createOrUpdateAnswer(answerContent);

    if (this.qcs[this.upgs.qcCurrentIdx].section.length === 1 && Number(this.qcs[this.upgs.qcCurrentIdx + 1].section) < this.upgs.currentMainSection) {
      this.upgs.currentMainSection--;
      this.progressBar.back();
    }
    if (this.upgs.qcCurrentIdx > 0) {
      this.upgs.qcCurrentIdx--;
    } else {
      this.router.navigate(['/' + StartRoutePath], this.dataService.getNavExtras());
    }

    this.saveUserProgress();
  }

  userQuestionnaireComplete() {

    // Update UserQuestionnaire status to "published"
    let updateObj = {
      ...this.userQuestionnaire,
      status: UserQuestionnaireStatus.Published,
      updated: this.dataService.currentTS(),
      updatedBy: this.userQuestionnaire.userToken
    };
    updateObj = objectKeyFilter(updateObj, ['__typename'], true);
    delete updateObj.questionnaire;

    this.updateUserQuestionnaireGQL.mutate({
      input: {
        ...updateObj
      }
    }).subscribe();

    // Update Survey's "questionnairesCompleted"
    const survey = this.dataService.userSessionData.survey;
    const updateSurveyObj = {
      ...survey,
      questionnairesCompleted: survey.questionnairesCompleted ? survey.questionnairesCompleted + 1 : 1,
      updated: this.dataService.currentTS(),
      updatedBy: this.dataService.userSessionData.userToken
    };
    delete updateSurveyObj.questionnaire;
    delete updateSurveyObj.answers;
    this.updateSurveyGQL.mutate({
      input: updateSurveyObj
    }).subscribe();

    // Don't remove the user questionnaire - the graphical evaluation works with this data
    // this.deleteUserQuestionnaireGQL.mutate({
    //   input: { id: this.userQuestionnaire.id }
    // }).subscribe(result => {
    //   console.log('UserQuestionnaire ' + this.userQuestionnaire.id + ' removed from database.');
    // }, error => {
    //   console.error(error);
    // });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
