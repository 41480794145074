import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { QuestionContext } from '../questionnaire.component';
import {
  AnswerContent,
  Option,
  QuestionnaireType,
  QuestionType,
  UserQuestionnaireStatus
} from '../../shared/graphql/graphql-client.service';
import { AudioService } from '../../shared/audio.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { disableBrowserButtonNav, UserTokenCacheKey } from '../../../const.exports';
import { LocalStorageService } from '../../shared/local-storage.service';
import { DataService } from '../../shared/data.service';

declare var $: any;

@Component({
  selector: 'bkp-question-and-answer',
  templateUrl: './question-and-answer.component.html',
  styles: []
})
export class QuestionAndAnswerComponent implements OnChanges {

  $ = $;
  prod = environment.production;
  QuestionType = QuestionType;

  textAnswer: string;

  optionsSelected: Option[] = [];

  qPrefix: string;

  @Input() qc: QuestionContext;

  @Output() nextQCEvent = new EventEmitter<AnswerContent>();
  @Output() previousQCEvent = new EventEmitter<AnswerContent>();
  @Output() userQuestionnaireCompleteEvent = new EventEmitter();

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private audioService: AudioService
  ) {
    disableBrowserButtonNav();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (this.qc) {
        if (this.qc.answer) {
          if (this.qc.answer.answer.type === QuestionType.TextInput) {
            this.textAnswer = this.qc.answer.answer.text;
          } else if (this.qc.answer.answer.type === QuestionType.SingleChoice
            || this.qc.answer.answer.type === QuestionType.MultipleChoice) {
            this.optionsSelected = this.qc.answer.answer.optionsSelected;
            this.optionsSelected.forEach((os: Option) => {
              this.setOption('choice' + os.id, true);
            });
          } else if (this.qc.answer.answer.type === QuestionType.SingleCountInput
            || this.qc.answer.answer.type === QuestionType.MultipleCountInput) {
            this.optionsSelected = this.qc.answer.answer.optionsSelected;
            this.optionsSelected.forEach((os: Option) => {
              this.setInputValue('input' + os.id, String(os.count));
            });
          }
        } else {
          if (this.qc.question) {
            if (this.qc.question.type === QuestionType.SingleCountInput
              || this.qc.question.type === QuestionType.MultipleCountInput) {
              this.optionsSelected = this.qc.question.optionSet;
            }
          }
        }

        this.qPrefix = (this.qc.questionnaireType === QuestionnaireType.SelbstCheck) ? 'se'
            : ((this.qc.questionnaireType === QuestionnaireType.FremdCheckDv
                  || this.qc.questionnaireType === QuestionnaireType.FremdCheckPa
                  || this.qc.questionnaireType === QuestionnaireType.FremdCheckBi) ? 'fe'
            : 'bc');
        this.playDescriptionAudio(true);
      }
    }, 100);
  }

  nextButtonEnabled() {
    return this.qc.section !== '6.1'
        && (this.optionsSelected.length > 0 || this.qc.question.type === QuestionType.TextInput);
  }

  speakerEnabled() {
    return this.qc.section !== '4';
  }

  finishSurveyEnabled() {
    return this.qc.headerMain === "Abschluss";
  }

  completed() {
    this.localStorageService.remove(UserTokenCacheKey);
    this.userQuestionnaireCompleteEvent.emit();
    this.router.navigate(['/finish']);
  }

  playDescriptionAudio(waitForPreviousAudio: boolean = false) {
    if (this.qc.section) {
      this.audioService.playOrPause(`${this.qPrefix}-${this.qc.section}.m4a`);
    }
    if (this.qc.question && this.qc.question.description) {
      this.audioService.playOrPause(`${this.qPrefix}-${this.qc.section}-e.m4a`, waitForPreviousAudio);
    }
    if (this.qc.isParent) {
      this.playInstructionAudio(waitForPreviousAudio);
    }
  }

  playInstructionAudio(waitForPreviousAudio: boolean = false) {
    this.audioService.playOrPause(`${this.qPrefix}-${this.qc.section}-i.m4a`, waitForPreviousAudio);
  }

  playAnswerAudio(id) {
    this.audioService.playOrPause(`${this.qPrefix}-${this.qc.section}-a${id ? id : ''}.m4a`);
  }

  setOption(elementId, active) {
    if (active) {
      if (!document.getElementById(elementId).classList.contains('active')) {
        document.getElementById(elementId).classList.add('active');
      }
    } else {
      document.getElementById(elementId).classList.remove('active');
    }
  }

  setInputValue(elementId, value) {
    const el = document.getElementById(elementId) as HTMLInputElement;
    if (el) {
      el.value = value;
    }
  }

  toggleActive(option: Option) {
    let elementId = 'choice' + option.id;

    if (this.qc.question.type === QuestionType.SingleChoice) {
      if (this.optionsSelected.length > 0) {
        this.setOption('choice' + this.optionsSelected[0].id, false);
        if (this.optionsSelected[0].id !== option.id) {
          this.setOption(elementId, true);
          this.optionsSelected[0] = option;
        } else {
          this.optionsSelected = [];
        }
      } else {
        this.setOption(elementId, true);
        this.optionsSelected[0] = option;
      }
    } else if (this.qc.question.type === QuestionType.MultipleChoice) {
      if (this.optionsSelected.length > 0) {
        let idx = this.optionsSelected.findIndex((os: Option) => os.id === option.id);
        if (idx !== -1) {
          this.setOption('choice' + this.optionsSelected[idx].id, false);
          this.optionsSelected.splice(idx, 1);
        } else {
          this.setOption(elementId, true);
          this.optionsSelected.push(option);
        }
      } else {
        this.setOption(elementId, true);
        this.optionsSelected.push(option);
      }
    }
  }

  sendQCEvent(event: EventEmitter<AnswerContent>) {
    if (this.qc.question) {
      if (this.qc.question.type === QuestionType.SingleChoice
          || this.qc.question.type === QuestionType.MultipleChoice
          || this.qc.question.type === QuestionType.SingleCountInput
          || this.qc.question.type === QuestionType.MultipleCountInput) {
        const answerContent: AnswerContent = <AnswerContent> {
          type: this.qc.question.type,
          optionsSelected: this.optionsSelected
        };
        event.emit(answerContent);
      } else if (this.qc.question.type === QuestionType.TextInput && this.textAnswer) {
        const answerContent: AnswerContent = <AnswerContent> {
          type: this.qc.question.type,
          text: this.textAnswer
        };
        event.emit(answerContent);
      } else {
        event.emit(undefined);
      }
    } else {
      event.emit(undefined);
    }
    this.textAnswer = '';
    this.optionsSelected = [];
  }

  next() {
    this.sendQCEvent(this.nextQCEvent);
  }

  previous() {
    this.sendQCEvent(this.previousQCEvent);
  }
}
