import { Component, Input, OnInit } from '@angular/core';
import { QuestionContext } from '../questionnaire.component';
import { AudioService } from '../../shared/audio.service';
import { Router } from '@angular/router';
import { disableBrowserButtonNav } from '../../../const.exports';

@Component({
  selector: 'bkp-user-comments',
  templateUrl: './user-comments.component.html',
  styles: []
})
export class UserCommentsComponent implements OnInit {

  @Input() qc: QuestionContext;

  constructor(private router: Router, private audioService: AudioService) {
    disableBrowserButtonNav();
  }

  ngOnInit() {
    this.audioService.playOrPause('se-6.1.m4a');
  }

  send() {
    this.router.navigate(['/userComments']);
  }
}
