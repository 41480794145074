import { Component, Input, OnInit } from '@angular/core';
import { UserProgress } from '../questionnaire.component';

@Component({
  selector: 'bkp-progress-bar',
  templateUrl: './progress-bar.component.html',
  styles: []
})
export class ProgressBarComponent implements OnInit {

  @Input() elements: string[];
  @Input() upgs: UserProgress;
  @Input() color: any;
  @Input() width: string;
  @Input() height: string;
  @Input() viewBox: string;

  // transient
  gElements = [];
  gXPos = 0;

  private createGElements(elId, color) {
    const type = elId.charAt(0);
    const dim = elId.substring(1);

    switch (type) {
      case 'L':
        for (let i = 0; i < 2; i++) {
          this.gElements.push({
            id: (i === 1) ? elId + '-D' : elId,
            type,
            x2: dim,
            strokeColor: (i === 0) ? 'lightgray' : color
          });
        }
        break;

      case 'C':
        for (let i = 0; i < 3; i++) {
          this.gElements.push({
            id: (i === 1) ? elId + '-C' : ((i === 2) ? elId + '-D' : elId),
            type,
            r: (i === 1) ? dim - 1 : dim,
            strokeColor: (i === 0) ? 'lightgray' : color,
            strokeWithCircle: (i === 0) ? '1px' : ((i === 1) ? '2px' : '0px'),
            fillColor: (i === 2) ? color : 'white'
          });
        }
        break;
    }
  }

  constructor() {}

  ngOnInit() {
    for (let i = 0; i < this.elements.length; i++) {
      if (!this.gElements.some(gEl => {
        return gEl.id === this.elements[i];
      })) {
        this.createGElements(this.elements[i], this.color);
      }
      this.upgs.useElements.push({
        id: 'pbEl' + (i + 1),
        refId: '#' + this.elements[i],
        status: '',
        x: this.addToGXPos(i)
      });
    }

    this.upgs.useElements[0].status = '-C';
  }

  addToGXPos(idx) {
    const typePrev = (idx > 0) ? this.elements[idx - 1].charAt(0) : null;
    const dimPrev = (idx > 0) ? Number.parseInt(this.elements[idx - 1].substring(1), 10) : 0;
    const typeCurr = this.elements[idx].charAt(0);
    const dimCurr = Number.parseInt(this.elements[idx].substring(1), 10);

    this.gXPos += (typeCurr === 'C') ? dimPrev + dimCurr : dimPrev;
    return this.gXPos;
  }

  incCurrentIdx(inc: number) {
    if (this.upgs.currentIdx + inc < this.upgs.useElements.length) {
      this.upgs.currentIdx += inc;
    }
  }

  progress() {
    this.upgs.useElements[this.upgs.currentIdx].status = '-D';  // -D: Done
    this.incCurrentIdx(1);
    this.upgs.useElements[this.upgs.currentIdx].status = '-D';
    this.incCurrentIdx(1);
    this.upgs.useElements[this.upgs.currentIdx].status = '-C';  // -C: Current
  }

  fwd() {
    this.upgs.useElements[this.upgs.currentIdx].status = '-D';
    this.incCurrentIdx(2);
    this.upgs.useElements[this.upgs.currentIdx].status = '-C';
  }

  back() {
    this.upgs.useElements[this.upgs.currentIdx].status = '-D';
    this.incCurrentIdx(-2);
    this.upgs.useElements[this.upgs.currentIdx].status = '-C';
  }
}
