import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { InfoFinish, InfoNotPublished, StartRoutePath, SurveyRoutePath } from '../const.exports';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { InfoComponent } from './shared/info/info.component';


const routes: Routes = [
  { path: '', redirectTo: StartRoutePath, pathMatch: 'full' },
  { path: StartRoutePath, component: DashboardComponent },
  { path: SurveyRoutePath, component: QuestionnaireComponent },
  { path: 'finish', component: InfoComponent, data: InfoFinish },
  { path: 'notPublished', component: InfoComponent, data: InfoNotPublished }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
